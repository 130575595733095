<template>
  <div v-if="isLoading">
   <CanPayLoader/>
  </div>
  <div class="container" v-else>

    <div>
      <div class="row py-5 align-items-center">
        <div class="col-12 py-5"></div>
        <div class="col-12 text-center" v-if="required_upload_document != 0">
          <button
            type="button"
            class="btn-black btn-verify"
            @click="uploadDocument"
          >
            Upload Bank Statement
          </button>
        </div>
        <div class="col-12 text-center">
          <button
            type="button"
            class="btn-black btn-verify"
            @click="disabledAccounts"
          >
            Disabled Accounts
          </button>
        </div>
        <div class="col-12 text-center" v-if="currentUser.banking_solution_name == 'finicity'">
          <button
            type="button"
            class="btn-black btn-verify"
            @click="financialInstitutions"
          >
            Financial Institutions
          </button>
        </div>
        <div class="col-12 py-4"></div>
        <div class="col-12 text-center">
          <label style="color: white; font-size: 1rem; margin-left: 0.5rem">
            Do you want to Deactivate your CanPay account?
          </label>
        </div>
        <div class="col-12 text-center mb-2">
          <button
            v-if="currentUser.can_deactive"
            type="button"
            class="btn-black btn-verify"
            @click="connectBankAccount"
          >
            Deactivate CanPay Account
          </button>
          <button
            v-else
            type="button"
            class="btn-black btn-verify btn-deactive"
          >
            Deactivate CanPay Account
          </button>
        </div>
        <div class="col-12 text-center">
          <button
            type="button"
            class="force-refresh-btn"
            @click="forceRefreshApp"
          >
            Refresh App
          </button>
        </div>
      </div>
    </div>

    <!----- MODAL FOR DEACTIVATE CONFIRMATION---->

    <div>
      <b-modal
        ref="confirm-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="confirm-modal"
        centered
      >
        <div class="color">
          <div class="col-12 text-center">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="120"
              height="120"
              viewBox="0 0 100 125"
              style="enable-background: new 0 0 100 125"
              xml:space="preserve"
              fill="#e14343"
            >
              <path
                d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
              />
            </svg>
          </div>
          <div class="purchaserpower-modal-text">
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ deactivate_msg }}
              </label>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <button
                  @click="hideModal('confirm-modal')"
                  class="btn btn-danger btn-md center-block tip-btn"
                >
                  <label class="forgetpassword-ok-label">Cancel</label>
                </button>
                <button
                  @click="deactivate()"
                  class="btn btn-danger btn-md center-block tip-btn"
                  style="
                    background-color: #dc3545 !important;
                    border-color: #dc3545 !important;
                  "
                >
                  <label class="forgetpassword-ok-label">Deactivate</label>
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>

    <div>
      <b-modal
        ref="email-success-verification-code-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        no-close-on-backdrop
        id="email-success-verification-code-modal"
        centered
        title="BootstrapVue"
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="pin-success-top-spacing"></div>
            <div class="row" style="margin-bottom: 5px">
              <div class="col-4"></div>
              <div class="col-4 text-center">
                <img
                  class="onboarding-success-modal-image"
                  src="../../assets/images/success-image.png"
                />
              </div>
              <div class="col-4"></div>
            </div>
            <div class="d-block text-center">
              <label class="success-popup-style">Success!</label>
            </div>

            <div class="d-block text-center">
              <label class="onboarding-success-popup-style"
                >Your account deactivated successfully</label
              >
            </div>
            <div class="success-bottom-spacing"></div>
          </div>
        </div>
      </b-modal>
    </div>

    <!---- MODAL FOR  Error Modal  ---->
    <div>
      <b-modal
        ref="zero-pp-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="zero-pp-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <div class="col-12 text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="120"
                height="120"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                fill="#e14343"
              >
                <path
                  d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                />
              </svg>
            </div>
            <div class="d-block text-center">
              <label class="purchasepower-def-label">
                {{ pp_alert_message }}
              </label>
            </div>
            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideModal('zero-pp-modal')"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import api from "../../api/login.js";
import accountAPI from "../../api/account.js";
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue";
export default {
  name: "Pay",
  components: {
    Loading,
    CanPayLoader
  },
  methods: {
    showModal(modal) {
      this.$refs[modal].show();
    },
    hideModal(modal) {
      this.$refs[modal].hide();
    },
    connectBankAccount() {
      this.showModal("confirm-modal");
    },

    deactivate() {
      var self = this;
      self.hideModal("confirm-modal");
      self.isLoading = true;
      accountAPI
        .consumerdeactivate()
        .then((response) => {
          if (response.code == 200) {
            this.showSuccessCodeModal();
          } else {
            // alert(response.message);
            self.isLoading = false;
          }
          console.log("then")
        })
        .catch(function (error) {
          self.pp_alert_message = error.response.data.message;
          self.showModal("zero-pp-modal");
          self.isLoading = false;
          console.log("catch")
        });
    },
    showSuccessCodeModal() {
      var self = this;
      this.$refs["email-success-verification-code-modal"].show();
      setTimeout(function () {
        // self.hideSuccessCodeModal();
        self.logout();
      }, 4000);
    },
    handleToggleDrawer() {
      this.$refs.drawerLayout.toggle();
    },
    logout() {
      var self = this;
      api
        .logout()
        .then((response) => {
          if (response.code == 200) {
            localStorage.removeItem("consumer_token");
            localStorage.removeItem("nopurchasepower");
            localStorage.removeItem("purchasepower");
            localStorage.removeItem("consumer_login_response");
            localStorage.removeItem("enrollment");
            delete axios.defaults.headers.common["Authorization"];
            self.$router.push("/login").catch((err) => {});
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    uploadDocument() {
      var self = this;
      self.$router.push("/uploaddocument").catch((err) => {});
    },
    disabledAccounts(){
      var self = this;
      self.$router.push("/disablebanklisting").catch((err) => {});
    },
    financialInstitutions(){
      var self = this;
      self.$router.push("/financialinstitutions").catch((err) => {});
    },
    checkUserRequriedDocumentStatus() {
      accountAPI
        .checkUserRequriedDocumentStatus()
        .then((response) => {
          if (response.code == 200) {
            this.required_upload_document = response.data;
          }
        })
        .catch(function (error) {
          self.isLoading = false;
        });
    },
    getUserdetails(){
      var self = this;
      accountAPI
      .getUserdetails()
      .then(function (response) {
        if (response.code == 200) {
          localStorage.setItem(
            "consumer_login_response",
            JSON.stringify(response.data)
          );
          self.currentUser = response.data
          if (self.currentUser.bank_link_type == 1) {
            self.manual_bank_link = false;
          }
        }
      })
      .catch(function (error) {
      });
    },
    forceRefreshApp() {
      location.reload(true);
    }
  },
  data() {
    let self = this;
    return {
      isLoading: false,
      fullPage: true,
      deactivate_msg:
        "Are you sure you want to Deactivate your CanPay Account? You will no longer be able to access the CanPay App or your CanPay account. You will only be able to reactivate your CanPay account in the future by contacting CanPay's customer service.",
      pp_alert_message: "",
      required_upload_document: 0,
      currentUser: {}
    };
  },
  created() {
    this.$root.$emit("loginapp", [""]);
    this.checkUserRequriedDocumentStatus();
  },
  mounted() {
    var element = document.getElementsByClassName("content-wrap");
    var elementTwo = document.getElementsByClassName("drawer-black-content");
    elementTwo[0].style.setProperty("background-color", "#149240");
    console.log(elementTwo);
    if (element[0]) {
      element[0].style.setProperty("background-color", "#149240");
    }
    this.$root.$emit("loginapp", [""]);
    this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
    this.getUserdetails();
  },
};
</script>

<style lang="scss">
#confirm-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
.btn-deactive{
  background: #282828!important;
  cursor: no-drop!important;
}
.force-refresh-btn{
  border: 0;
  width: 100%;
  padding: 15px 15px;
  background: #000;
  color: #fff;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
}
</style>
